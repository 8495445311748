* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.city {
  width: 1000px;
  height: 1000px;
  margin-left: -100px;
  perspective: 1000px;
}

.labyrinth {
  width: 1000px;
  height: 1000px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-500px);
  transition: transform 1s;
}

.labyrinth.show-edge {
  transform: translateZ(-100px) rotateY(45deg) rotateX(45deg);
}

.labyrinth__wilderness {
  position: absolute;
  width: 1000px;
  height: 1000px;
  border: 2px solid black;
  line-height: 1000px;
  font-size: 40px;
  font-weight: bold;
  color: rgba(17, 17, 17, 0.5);
  text-align: center;
}

.labyrinth__wilderness::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(212, 50, 50, 0.35),
    transparent 1%
  );

  animation: fudge 7s ease-in-out alternate infinite;
}

.labyrinth__wilderness--right::before {
  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(238, 255, 0, 0.25),
    transparent 1%,
    transparent,
    transparent 2%
  );
}

.labyrinth__wilderness--top::before {
  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(174, 59, 110, 0.45),
    transparent 1%,
    transparent,
    transparent 2%
  );
}

.labyrinth__wilderness--back::before {
  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(74, 129, 65, 0.65),
    transparent 1%,
    transparent,
    transparent 2%,
    rgba(74, 129, 65, 0.65),
    transparent 3%,
    transparent,
    transparent 4%,
    rgba(74, 129, 65, 0.8),
    transparent 5%,
    transparent,
    transparent 6%,
    rgba(74, 129, 65, 0.8),
    transparent 7%,
    transparent,
    transparent 8%,
    rgba(74, 129, 65, 0.65),
    transparent 9%,
    transparent,
    transparent 10%,
    rgba(74, 129, 65, 0.8),
    transparent 11%,
    transparent,
    transparent 12%,
    rgba(74, 129, 65, 0.8),
    transparent 13%,
    transparent,
    transparent 14%,
    rgba(74, 129, 65, 0.8),
    transparent 15%,
    transparent,
    transparent 16%,
    rgba(74, 129, 65, 0.65),
    transparent 17%,
    transparent,
    transparent 18%,
    rgba(74, 129, 65, 0.65),
    transparent 19%,
    transparent,
    transparent 20%,
    rgba(74, 129, 65, 0.8),
    transparent 21%,
    transparent,
    transparent 22%,
    rgba(74, 129, 65, 0.8),
    transparent 23%,
    transparent,
    transparent 24%,
    rgba(74, 129, 65, 0.65),
    transparent 25%,
    transparent,
    transparent 26%,
    rgba(74, 129, 65, 0.65),
    transparent 27%,
    transparent,
    transparent 28%,
    rgba(74, 129, 65, 0.65),
    transparent 29%,
    transparent,
    transparent 30%,
    rgba(74, 129, 65, 0.8),
    transparent 31%,
    transparent,
    transparent 32%
  );
}

//.labyrinth__wilderness--right  { background: hsla( 60, 100%, 50%, 0.7); }
//.labyrinth__wilderness--back   { background: hsla(120, 100%, 50%, 0.7); }
//.labyrinth__wilderness--top    { background: hsla(240, 100%, 50%, 0.7); }

.labyrinth__wilderness--right {
  transform: rotateY(90deg) translateZ(500px);
}
.labyrinth__wilderness--back {
  transform: rotateY(180deg) translateZ(500px);
}
.labyrinth__wilderness--top {
  transform: rotateX(90deg) translateZ(500px);
}

label {
  margin-right: 10px;
}
